@import "@/scss/_variables.scss";













































.basic-settings {
  grid-column-gap: 32px;

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr !important;
    grid-row-gap: 24px;
  }

  .cantilever-image {
    width: 100%;
    height: auto;
    margin-top: 16px;
  }
}
